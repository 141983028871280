

















import { defineComponent } from '@/plugins/composition';
import LoginForm from './components/LoginForm.vue';
import { routeNames } from '@/router/routeNames';
import SubscriptionPage from '@/layouts/SubscriptionLayout/SubscriptionPage.vue';

export default defineComponent({
  components: { LoginForm, SubscriptionPage },
  name: 'LoginPage',
  setup() {
    return {
      routeNames,
    };
  },
});
